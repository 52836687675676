<!--表单页面拷贝开始-->
<template>
  <div>
    <form-components @submitForm="submitForm">
      <template v-slot:content>
        <el-form-item label="代码类别">
          <el-col :span="8">
            <el-input v-model.trim="tripDictionary.category"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="代码名称">
          <el-col :span="8">
            <el-input v-model.trim="tripDictionary.code"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="代码值">
          <el-col :span="8">
            <el-input v-model.trim="tripDictionary.value"></el-input>
          </el-col>
        </el-form-item>

        
        <el-form-item label="排序">
          <el-col :span="8">
            <el-input v-model.trim="tripDictionary.sort"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="备注信息">
          <el-col :span="8">
            <el-input v-model.trim="tripDictionary.remark"></el-input>
          </el-col>
        </el-form-item>
      </template>
    </form-components>
  </div>
</template>
  
  <script>
import FormComponents from "@/components/form/FormComponents";
import TripDictionaryApi from "@/api/TripDictionaryApi";
import { navTo, queryParam } from "@/utils/NavigatorUtils";
export default {
  name: "SaveTripDictionaryPage",
  props: {
    msg: String,
  },
  components: {
    FormComponents,
  },
  data() {
    return {
      tripDictionary: {
        category: "",
        code: "",
        value: "",
        sort: "",
        remark: "",
      },
    };
  },
  created() {
    this.tripDictionary = this.$route.params;
  },
  methods: {
    submitForm() {
      this.tripDictionary.id > 0
        ? this.updateTripDictionaryById()
        : this.addTripDictionary();
    },
    addTripDictionary() {
      TripDictionaryApi.saveTripDictionary(this.tripDictionary).then((resp) => {
        if (resp.code === '200') {
          this.tripDictionary = {};
          this.$successMsg(resp.msg);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    updateTripDictionaryById() {
      TripDictionaryApi.updateTripDictionary(this.tripDictionary).then(
        (resp) => {
          if (resp.code === '200') {
            this.user = {};
            this.$successMsg(resp.msg);
            navTo(this, "/tripDictionaryList");
          } else {
            this.$errorMsg(resp.msg);
          }
        }
      );
    },
  },
};
</script>
  
  
  <!--表单页面拷贝结束-->